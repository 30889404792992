<template>
	<div class="transaction-list">
		<div class="transaction-group" v-for="date in transactionGroups" :key="date">
			<div class="transaction-date">{{ date }}</div>
			<router-link :to="{ name: 'transaction detail', params: { transactionID: txn.id }}" class="transaction" v-for="txn in groupedTransactions[date]" :key="txn.id">
				<div class="transaction-type">{{ friendlyType(txn.tags[0]) }}</div>
				<div class="transaction-amount">
					<div :class="{ 'amount-primary': true, 'amount-negative': txn.siacoinAmount.lt(0) }">{{ formatSiacoinString(txn.siacoinAmount) }}</div>
					<div class="amount-secondary">{{ formatCurrencyString(txn.siacoinAmount, txn.exchange_rate) }} <span class="exchange-rate">@ {{ formatRate(txn.exchange_rate) }}</span></div>
				</div>
				<div class="transaction-notes" v-for="(note, i) in txn.notes" :key="i">{{ note }}</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import { formatSiacoinString, formatCurrencyString, formatExchangeRate } from '@/format';
import { BigNumber } from 'bignumber.js';
import { mapState } from 'vuex';

export default {
	props: {
		transactions: Array
	},
	computed: {
		...mapState(['currencyCode']),
		transactionGroups() {
			const dates = Object.keys(this.groupedTransactions);

			return dates.sort((a, b) => {
				const ad = new Date(a),
					bd = new Date(b);

				if (ad > bd)
					return -1;

				if (ad < bd)
					return 1;

				return 0;
			});
		},
		groupedTransactions() {
			return this.transactions.reduce((groups, txn) => {
				const timestamp = new Date(txn.timestamp).toLocaleString([], {
					year: 'numeric',
					month: 'short',
					day: 'numeric'
				});

				if (!groups[timestamp])
					groups[timestamp] = [];

				txn.siacoinAmount = new BigNumber(txn.siacoin_credit).minus(txn.siacoin_debit);
				txn.siafundAmount = new BigNumber(txn.siafund_credit).minus(txn.siacoin_debit);

				groups[timestamp].unshift(txn);

				return groups;
			}, {});
		}
	},
	methods: {
		formatSiacoinString,
		formatCurrencyString(val, exchangeRate) {
			return formatCurrencyString(val, exchangeRate?.rate, exchangeRate?.currency);
		},
		formatRate(exchangeRate) {
			return formatExchangeRate(exchangeRate?.rate || 0, exchangeRate?.currency, 'never');
		},
		friendlyType(tag) {
			switch (tag) {
			case 'coin_burn':
				return 'Coin Burn';
			case 'defrag':
				return 'Defrag';
			case 'siafund_transaction':
				return 'Siafund Transaction';
			case 'storage_proof':
				return 'Storage Proof';
			case 'contract_renewal':
				return 'Contract Renewal';
			case 'contract_formation':
				return 'Contract Formation';
			case 'contract_revision':
				return 'Contract Revision';
			case 'host_announcement':
				return 'Host Announcement';
			case 'foundation_subsidy':
				return 'Foundation Subsidy';
			default:
				return 'Siacoin Transaction';
			}
		}
	}
};
</script>

<style lang="stylus" scoped>
.transaction-date {
	position: sticky;
	top: 0;
	background: bg;
	color: rgba(255, 255, 255, 0.54);
	padding: 15px;
	border-bottom: 1px solid #2d2d2d;
	z-index: 1;
}

.transaction {
	display: grid;
	width: 100%;
	grid-template-columns: repeat(2, auto);
	padding: 15px;
	grid-gap: 15px;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #2d2d2d;
	text-decoration: none !important;

	&:hover, &:focus, &:active {
		background: darken(bg, 5%);
	}

	.amount-primary, .amount-secondary {
		text-align: right;
		white-space: nowrap;
	}

	.amount-primary {
		color: primary;
		font-size: 1.3rem;

		&.amount-negative {
			color: negative;
		}
	}

	.amount-secondary {
		font-size: 1rem;
		color: rgba(255, 255, 255, 0.54);
	}

	.transaction-type {
		color: rgba(255, 255, 255, 0.84);
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.transaction-notes {
		grid-column: 1 / -1;
		font-style: italic;
		font-size: 0.9rem;
		padding: 0 10px;
		color: rgba(255, 255, 255, 0.54);
	}
}
</style>