<template>
	<div class="page">
		<div class="details">
			<div class="detail">
				<div class="balance-primary">{{ formatSiacoinString(siacoinBalance) }}</div>
				<div class="balance-secondary">{{ formatCurrencyString(siacoinBalance) }} <span class="exchange-rate">@ {{ formattedRate }}</span></div>
			</div>
			<div class="detail subsidy-detail">
				<div class="detail-secondary">Next subsidy in {{ friendlySubsidy }}</div>
				<div class="detail-secondary">{{ subsidyDate }}</div>
			</div>
		</div>
		<transaction-list :transactions="transactions" />
	</div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { getFoundationWallet, getCurrentBlockHeight } from '@/api';
import { formatSiacoinString, formatCurrencyString, formatExchangeRate } from '@/format';

import { mapState, mapActions } from 'vuex';
import TransactionList from '../components/TransactionList.vue';

export default {
	components: { TransactionList },
	computed: {
		...mapState(['exchangeRates', 'currencyCode']),
		nextSubsidy() {
			return 4380 - (this.height - 298144) % 4380;
		},
		friendlySubsidy() {
			const denoms = [
				{
					n: 4360,
					label: 'month'
				},
				{
					n: 144,
					label: 'day'
				},
				{
					n: 6,
					label: 'hour'
				}
			];

			if (this.nextSubsidy <= 6)
				return 'less than an hour';

			let rem = this.nextSubsidy;
			return denoms.reduce((g, d) => {
				if (g.length >= 2)
					return g;

				const v = Math.floor(rem / d.n);

				rem = rem % d.n;

				if (v > 0)
					g.push(`${v} ${d.label}${v > 1 ? 's' : ''}`);

				return g;
			}, []).join(' ');
		},
		subsidyDate() {
			const n = Date.now() + (this.nextSubsidy * 6e5);

			return new Date(n).toLocaleString([], {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			});
		},
		formattedRate() {
			return formatExchangeRate(this.exchangeRates[this.currencyCode], this.currencyCode, 'never');
		}
	},
	data() {
		return {
			height: 0,
			transactions: [],
			siacoinBalance: new BigNumber(0),
			siafundBalance: new BigNumber(0)
		};
	},
	async beforeMount() {
		await Promise.all([
			this.loadWallet(),
			this.loadBlockHeight()
		]);
	},
	methods: {
		...mapActions(['setCurrencyCode']),
		formatSiacoinString(val) {
			return formatSiacoinString(val, 'never');
		},
		formatCurrencyString(val) {
			return formatCurrencyString(val, this.exchangeRates[this.currencyCode], this.currencyCode, 'never');
		},
		async loadWallet() {
			const wallet = await getFoundationWallet(this.currencyCode);

			this.siacoinBalance = new BigNumber(wallet.unspent_siacoin_balance);
			this.siafundBalance = new BigNumber(wallet.unspent_siafund_balance);
			this.transactions = wallet.transactions;
		},
		async loadBlockHeight() {
			this.height = await getCurrentBlockHeight();
		}
	}
};
</script>

<style lang="stylus" scoped>
.page {
	max-width: 1024px;
	margin: 0 auto;
	border: 1px solid #2d2d2d;
}

.title {
	padding: 15px;
}

.details {
	text-align: center;
	padding: 15px;
	border-bottom: 1px solid #2d2d2d;
	background: bg;
	z-index: 1;

	.detail {
		margin-bottom: 5px;

		&.subsidy-detail {
			font-style: italic;
		}

		.detail-title {
			color: rgba(255, 255, 255, 0.54);
			margin-bottom: 2px;
		}

		.balance-primary {
			color: primary;
			font-size: 2rem;
		}

		.balance-secondary {
			font-size: 1.5rem;
		}

		.balance-secondary, .detail-secondary {
			color: rgba(255, 255, 255, 0.54);
		}
	}
}
</style>